<template>
    <div class="booth-ad-content">
        <div class="header">趋势分析</div>
        <div class="chart-tab">
            <el-checkbox-group v-model="checkList">
                <el-checkbox :label="checkItem" v-for="checkItem in categoryList" :key="checkItem"></el-checkbox>
            </el-checkbox-group>
            <div class="data-content" id="boothAd"></div>
        </div>
        <div class="cut-off"></div>
        <div class="header area-header">
            <div class="item-title">地区分布</div>
            <div class="focus-text">
                <span class="chat-content">
                    <span>成交客户主集中在</span>
                <span class="focus-city"
                      v-for="(customerItem, customerIndex) in customerList" :key="customerIndex">
                    {{customerItem}}<span v-if="customerIndex !== customerList.length - 1">、</span>
                </span>
                </span>
                <div class="angleLeft">
                    <span class="angleContent"></span>
                </div>
            </div>
        </div>
        <div class="area-table">
            <el-table :data="areaList" height="520px" style="width: 100%; margin-top: 10px; flex: 1" size="medium"
                      :header-cell-style="{fontWeight: 'normal', height: '48px', color: '#666666', background: '#F5F5F5'}"
                      :cell-style="{fontSize: '14px',color: '#343434'}">
                <el-table-column align="center" label="排名" width="60">
                    <template slot-scope="scope">
                        <div class="ranking"
                             v-if="(scope.$index === 0 || scope.$index === 1 || scope.$index === 2) && listPages.currentPageNum === 1">
                        </div>
                        <div v-else style="line-height: 24px">{{rankText(scope.$index)}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="provinces" align="center" label="地区"></el-table-column>
                <el-table-column align="center"  label="点击率">
                    <template slot-scope="scope">
                        {{scope.row.click_rate}}%
                    </template>
                </el-table-column>
                <el-table-column align="center" label="转化率">
                    <template slot-scope="scope">
                        {{scope.row.conversion_rate}}%
                    </template>
                </el-table-column>
                <el-table-column align="left" label="占比">
                    <template slot-scope="scope">
                        <div class="area-item">
                            <el-progress :percentage="getPercent(scope.row.potential_customers)" :show-text="false"></el-progress>
                            <span class="percentage">{{scope.row.value}}%</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="operation-pagination" style="margin: 20px 0;text-align: center"
                           :current-page="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           :total="listPages.total"
                           layout="prev, pager, next, jumper"
                           @current-change="handleCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {dataAnalysisTime,dataAnalysisArea} from "@/utils/searchPromotion";
import {errMsg} from "@/utils/common";

export default {
        name: "BoothAd",
        data() {
            return {
                boothAd: null,
                checkList: ['展现次数', '点击率', '转化率'],
                categoryList: ['展现次数', '点击率', '转化率'],
                allSeries: [],
                customerList: [],
                //地区分布
                areaList: [],
                //分页
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                denominator: null,
                areaTotal: 0,
                relativeData: 0,
            }
        },
        mounted() {
            this.getBoothAdInfo();
            this.getAreaList()
            this.boothAd = this.$echarts.init(document.getElementById('boothAd'));

            let resizeTimer = null;
            window.onresize = () => {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                    this.boothAd.resize()
                }, 100)
            }
        },
        computed: {
            series() {
                return this.allSeries.filter(item => {
                    return this.checkList.includes(item.name)
                })
            }
        },
        watch: {
            series(val) {
                this.getTrendECharts()
            }
        },
        methods: {
            getPercent(value) {
                return Number((value * 100 / this.relativeData).toFixed(2))
            },
            rankText(rank) {
                return (this.listPages.currentPageNum - 1) * this.listPages.eachPageNum + rank +  1;
            },
            // 获取eChart图表
            async getBoothAdInfo() {
                try {
                    const {code, data ,msg} = await dataAnalysisTime();
                    console.log("data", data)
                    if (code === 200) {
                        this.allSeries = [
                            {
                                name: "展现次数",
                                type: "line",
                                smooth: true,
                                data: data.show_num.slice(1).concat(data.show_num[0]),
                                lineStyle: {
                                    color: "#E43A3C"
                                },
                                itemStyle: {
                                    color: "#E43A3C"
                                }
                            },
                            {
                                name: "点击率",
                                type: "line",
                                smooth: true,
                                data: data.click_rate.slice(1).concat(data.click_rate[0]).map((elem) => {
                                    return Number(elem.toFixed(2))
                                }),
                                yAxisIndex: 1,
                                lineStyle: {
                                    color: "#26C6DA"
                                },
                                itemStyle: {
                                    color: "#26C6DA"
                                }
                            },
                            {
                                name: "转化率",
                                type: 'line',
                                smooth: true,
                                data: data.conversion_rate.slice(1).concat(data.conversion_rate[0]).map((elem) => {
                                    return Number(elem.toFixed(2))
                                }),
                                yAxisIndex: 1,
                                lineStyle: {
                                    color: "#2088E5"
                                },
                                itemStyle: {
                                    color: "#2088E5"
                                }
                            }

                        ];
                    }
                } catch (e) {

                }



            },
            // 趋势图
            getTrendECharts() {
                this.boothAd.setOption({
                    xAxis: {
                        type: 'category',
                        data: ['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
                            '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
                        boundaryGap: false,
                        axisLabel: {
                            textStyle: {
                                color: '#666'
                            },
                            align: 'center'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                    },
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#666'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    },{
                        type: 'value',
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#666'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    }],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        backgroundColor: '#fff',
                        textStyle: {
                            color: '#666',
                        },
                        borderColor: '#DCDCDC',
                        borderWidth: 2,
                        formatter: (params) => {
                            let res = params[0].name;
                            for (let i = 0; i < params.length; i++) {
                                if (params[i].seriesName === '点击率' || params[i].seriesName === '转化率') {
                                    res += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value + '%';
                                } else {
                                    res += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value;
                                }
                            }
                            return res
                        }
                    },
                    legend: {
                        icon: 'circle',
                        // data: this.checkList,
                        bottom: 0,
                        selectedMode: false
                    },
                    grid: {
                        top: 20,
                        left: 80,
                        right: 40,
                        bottom: 80,
                    },
                    series: this.series
                }, true);
            },
            // 获取地区分布数据
            async getAreaList() {
                let params = {
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum
                }
                try {
                    const {code, data, msg} = await dataAnalysisArea(params)
                    if (code === 200) {
                        this.areaTotal = data.sum;
                        this.areaList = data.list.map(elem => {
                            elem["click_rate"] = Number(elem["click_rate"].toFixed(2))
                            elem["value"] = Number((elem.potential_customers * 100 / data.sum).toFixed(2));
                            elem["conversion_rate"] = Number(elem["conversion_rate"].toFixed(2))
                            return elem
                        })
                        this.listPages.total = data.total;
                        if (this.listPages.currentPageNum === 1) {
                            this.relativeData = data.list[0]["potential_customers"]
                            this.customerList = data.list.slice(0, 3).map(elem => {
                                return elem.provinces;
                            })
                        }
                    }
                } catch (e) {
                    errMsg(e)
                }

            },
            //分页
            handleCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getAreaList()
            }
        }
    }
</script>

<style scoped lang="scss">
    .booth-ad-content {
        /*margin: 0 20px;*/
        .header {
            margin: 0 20px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #343434;
        }

        .area-header {
            display: flex;
            align-items: center;
            margin: 20px;

            .focus-text {
                font-size: 14px;
                color: #343434;
                margin-left: 13px;
                font-weight: 400;

                .focus-city {
                    font-size: 14px;
                    color: #FF0000;
                }
            }
        }

        .item-title {
            font-size: 18px;
            position: relative;
            margin-left: 15px;

            &:before {
                content: '';
                width: 5px;
                height: 20px;
                background: #2DC079;
                border-radius: 3px;
                position: absolute;
                left: -13px;
                top: 3px;
            }
        }

        .focus-text {
            height: 30px;
            background: #FFFFFF;
            border: 1px solid #DCDCDC;
            border-radius: 4px;
            position: relative;
            display: flex;
            align-items: center;
            margin-left: 10px;

            .chat-content {
                margin: 0 10px;

                .red-text {
                    color: #FF0000;
                }
            }

            .angleLeft {
                position: absolute;
                border: 6px solid transparent;
                top: 18px;
                margin-top: -8px;
                left: -14px;
                border-right: 8px solid #ededed;
            }

            .angleContent {
                position: absolute;
                border: 4px solid transparent;
                top: 50%;
                margin-top: -4px;
                z-index: 99;
                left: -1.5px;
                border-right: 6px solid #ffffff;
            }
        }

        .chart-tab {
            margin: 20px 20px 15px;
            display: flex;
            flex-direction: column;

            .data-content {
                height: 330px;
                margin-top: 30px;
            }

            // 多选框的颜色
            ::v-deep .el-checkbox-group {
                .el-checkbox {
                    .el-checkbox__input {
                        .el-checkbox__inner {
                            &:hover {
                                border-color: #0083FF;
                            }
                        }

                        &.is-focus {
                            .el-checkbox__inner {
                                border-color: #0083FF;
                            }
                        }
                    }

                    &.is-checked {
                        .el-checkbox__input {
                            &.is-checked {
                                .el-checkbox__inner {
                                    background-color: #0083FF;
                                    border-color: #0083FF;
                                }
                            }

                            &:hover {
                                border-color: #0083FF;
                            }
                        }

                        .el-checkbox__label {
                            color: #343434;
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        .cut-off {
            width: 100%;
            height: 20px;
            background: #F7F9FB;
        }

        .area-table {
            margin: 0 20px;

            ::v-deep .el-table {
                .el-table__body-wrapper {
                    .el-table__row {
                        .ranking {
                            width: 24px;
                            height: 24px;
                            line-height: 24px;
                            text-align: center;
                            font-weight: 500;
                            background-size: cover;
                            display: inline-block;
                        }

                        &:first-child {
                            .ranking {
                                background: url("../../../assets/images/student/num-01.png") no-repeat center;
                            }
                        }

                        &:nth-of-type(2) {
                            .ranking {
                                background: url("../../../assets/images/student/num-02.png") no-repeat center;
                            }
                        }

                        &:nth-of-type(3) {
                            .ranking {
                                background: url("../../../assets/images/student/num-03.png") no-repeat center;
                            }
                        }
                    }
                }
            }
        }
    }

    .area-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .percentage {
            width: 48px;
            text-align: right;
        }

        ::v-deep .el-progress {
            width: 1%;
            flex: 1;
            padding: 0 10px;
        }
    }
</style>
